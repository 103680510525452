const bizOpsTeamRoutes = {
  path: "/biz_ops_team/",
  component: () => import("src/layouts/BizOpsTeamLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "BizOpsDashboardPage",
      component: () => import("pages/bizOpsTeam/BizOpsDashboardPage.vue"),
    },
    {
      path: "skus",
      name: "BizOpsTeamSKUs",
      children: [
        {
          path: "bidso_skus",
          name: "BizOpsManageBidsoSKUs",
          component: () => import("pages/bizOpsTeam/BizOpsManageBidsoSKUs.vue"),
        },
        {
          path: "bidso_skus/create",
          name: "BizOpsCreateBidsoSKU",
          component: () => import("pages/bizOpsTeam/BizOpsCreateBidsoSKU.vue"),
        },
        {
          path: "bidso_skus/:bidsoSKUId/edit",
          name: "BizOpsEditBidsoSKU",
          component: () => import("pages/bizOpsTeam/BizOpsEditBidsoSKU.vue"),
        },
        {
          path: "seller_skus/live",
          name: "BizOpsManageSellerLiveSKUs",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageSellerLiveSKUs.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "BizOpsManageSellerDraftSKUs",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageSellerDraftSKUs.vue"),
        },
        {
          path: "seller_skus/:skuId/edit",
          name: "BizOpsEditSellerSKU",
          component: () => import("pages/bizOpsTeam/BizOpsEditSellerSKU.vue"),
        },
        {
          path: "sample_skus",
          name: "BizOpsTeamSampleSKUs",

          children: [
            {
              path: "",
              name: "BizOpsTeamViewSampleSKUs",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamViewSampleSKUs.vue"),
            },
            {
              path: "create",
              name: "BizOpsTeamCreateSampleSKU",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamCreateSampleSKU.vue"),
            },
            {
              path: ":sampleSKUId/edit",
              name: "BizOpsTeamEditSampleSKU",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamEditSampleSKU.vue"),
            },
          ],
        },

        {
          path: "buyer_skus",
          name: "BizOpsViewBuyerSKUs",
          component: () =>
            import("src/pages/bizOpsTeam/BizOpsViewBuyerSKUs.vue"),
        },
      ],
    },
    {
      path: "sample_orders",
      name: "BizOpsTeamManageSampleOrders",
      component: () =>
        import("pages/bizOpsTeam/BizOpsTeamManageSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "BizOpsTeamManageSampleOrder",
          component: () =>
            import("pages/bizOpsTeam/BizOpsTeamManageSampleOrder.vue"),
        },
      ],
    },

    {
      path: "master_orders",
      name: "BizOpsMasterOrders",
      children: [
        {
          path: "",
          name: "BizOpsSearchMasterOrders",
          component: () =>
            import("pages/bizOpsTeam/BizOpsSearchMasterOrders.vue"),
        },
        {
          path: "qc/calendar",
          name: "BizOpsOrdersQCCalendar",
          component: () =>
            import("pages/bizOpsTeam/BizOpsOrdersQCCalendar.vue"),
        },
        {
          path: "qc/reports",
          name: "BizOpsQCReports",
          component: () => import("pages/bizOpsTeam/BizOpsQCReports.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "BizOpsViewMasterOrder",
      component: () => import("pages/bizOpsTeam/BizOpsViewMasterOrder.vue"),
    },

    {
      path: "logistics/",
      children: [
        {
          path: "transport_requests",
          name: "BizOpsManageTransportRequests",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageTransportRequests.vue"),
        },
        {
          path: "transport_requests/add/:transportType",
          name: "BizOpsCreateTransportRequest",
          component: () =>
            import("pages/bizOpsTeam/BizOpsCreateTransportRequest.vue"),
        },
        {
          path: "transport_requests/:requestId/edit",
          name: "BizOpsEditTransportRequest",
          component: () =>
            import("pages/bizOpsTeam/BizOpsEditTransportRequest.vue"),
        },
        {
          path: "transport_bookings",
          name: "BizOpsManageTransportBookings",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageTransportBookings.vue"),
        },
        {
          path: "transport_requests/:requestId/transport_bookings/:bookingId/edit",
          name: "BizOpsEditTransportBooking",
          component: () =>
            import("pages/bizOpsTeam/BizOpsEditTransportBooking.vue"),
        },
        {
          path: "transport_requests/:requestId/transport_bookings/:bookingId/edit/billing_details",
          name: "BizOpsEditTransportBillingDetails",
          component: () =>
            import("pages/bizOpsTeam/BizOpsEditTransportBillingDetails.vue"),
        },
        {
          path: "transport_payment_dues",
          name: "BizOpsManageTransportDues",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageTransportDues.vue"),
        },
        {
          path: "settlements/buyers/:buyerId",
          name: "BizOpsManageBuyerSettlements",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageBuyerSettlements.vue"),
        },
      ],
    },

    {
      path: "sellers/view",
      name: "BizOpsViewSellers",
      component: () => import("pages/bizOpsTeam/BizOpsViewSellers.vue"),
    },
    {
      path: "buyers/view",
      name: "BizOpsViewBuyers",
      component: () => import("pages/bizOpsTeam/BizOpsViewBuyers.vue"),
    },

    {
      path: "profile",
      name: "BizOpsEditProfile",
      component: () => import("pages/bizOpsTeam/BizOpsProfile.vue"),
    },
    {
      path: "bidso/addresses",
      name: "BizOpsManageBidsoAddresses",
      component: () =>
        import("src/pages/bizOpsTeam/BizOpsManageBidsoAddresses.vue"),
    },
  ],
};
export default bizOpsTeamRoutes;
