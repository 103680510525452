const devAdminRoutes = [
  {
    path: "/dev_admin/",
    component: () => import("layouts/DevAdminPortalLayout.vue"),
    children: [
      {
        path: "products",
        name: "DevAdminManageProductPage",
        component: () => import("pages/devAdmin/DevAdminManageProduct.vue"),
      },
      {
        path: "products/:productId/edit",
        name: "DevAdminEditProductPage",
        component: () => import("pages/devAdmin/DevAdminEditProduct.vue"),
      },
      {
        path: "flags",
        name: "DevAdminManageFlagPage",
        component: () => import("pages/devAdmin/DevAdminManageFlag.vue"),
      },
    ],
  },
];

export default devAdminRoutes;
