const supplierTeamAgentRoutes = {
  path: "/supplier_team/agent",
  component: () => import("layouts/SupplierTeamAgentPortalLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "SupplierAgentDashboardPage",
      component: () =>
        import("pages/supplyTeam/SupplierAgentDashboardPage.vue"),
    },
    {
      path: "profile",
      name: "SupplierAgentProfile",
      component: () => import("pages/supplyTeam/SupplierAgentProfile.vue"),
    },
    {
      path: "sellers",
      name: "SupplierAgentManageSellers",
      component: () =>
        import("pages/supplyTeam/SupplierAgentManageSellers.vue"),
    },
    {
      path: "sellers/add",
      name: "SupplierAgentAddSeller",
      component: () => import("pages/supplyTeam/SupplierAgentAddSeller.vue"),
    },
    {
      path: "sellers/:sellerId",
      name: "SupplierAgentViewSeller",
      component: () => import("pages/supplyTeam/SupplierAgentViewSeller.vue"),
    },
    {
      path: "sellers/:sellerId/edit",
      name: "SupplierAgentEditSeller",
      component: () => import("pages/supplyTeam/SupplierAgentEditSeller.vue"),
    },
    {
      path: "sellers/:sellerId/catalog",
      name: "SupplierAgentSellerCatalog",
      component: () =>
        import("pages/supplyTeam/SupplierAgentSellerCatalog.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus",
      name: "SupplierAgentViewSellerSKUs",
      component: () =>
        import("pages/supplyTeam/SupplierAgentViewSellerSKUs.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus/:skuId",
      name: "SupplierAgentSellerSKUView",
      component: () =>
        import("pages/supplyTeam/SupplierAgentSellerSKUView.vue"),
    },

    {
      path: "skus",
      name: "SupplierAgentSKUs",
      children: [
        {
          path: "bidso_skus",
          name: "SupplierAgentManageBidsoSKUs",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageBidsoSKUs.vue"),
        },
        {
          path: "seller_skus/live",
          name: "SupplierAgentManageLiveSellerSKUs",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/create",
          name: "SupplierAgentCreateSellerSKU",
          component: () =>
            import("pages/supplyTeam/SupplierAgentCreateSellerSKU.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "SupplierAgentManageSellerDraftSKUs",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageSellerDraftSKUs.vue"),
        },
        {
          path: "seller_skus/:skuId/edit",
          name: "SupplierAgentEditSellerSKU",
          component: () =>
            import("pages/supplyTeam/SupplierAgentEditSellerSKU.vue"),
        },
      ],
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "SupplierAgentCompareSKUs",
      component: () => import("pages/supplyTeam/SupplierAgentCompareSKUs.vue"),
    },

    {
      path: "master_orders/:masterOrderId",
      name: "SupplierAgentViewMasterOrder",
      component: () =>
        import("pages/supplyTeam/SupplierAgentViewMasterOrder.vue"),
    },
    {
      path: "master_orders",
      name: "SupplierAgentMasterOrdersPath",
      children: [
        {
          path: "",
          name: "SupplierAgentMasterOrdersSearch",
          component: () =>
            import("pages/supplyTeam/SupplierAgentMasterOrdersSearch.vue"),
        },
        {
          path: "qc/reports",
          name: "SupplierAgentQCReports",
          component: () =>
            import("pages/supplyTeam/SupplierAgentQCReports.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "SupplierAgentManageBidsoAddresses",
      component: () =>
        import("src/pages/supplyTeam/SupplierAgentManageBidsoAddresses.vue"),
    },
    {
      path: "logistics/",
      children: [
        {
          path: "transport_requests",
          name: "SupplierAgentManageTransportRequests",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageTransportRequests.vue"),
        },
        {
          path: "transport_requests/add/:transportType",
          name: "SupplierAgentCreateTransportRequest",
          component: () =>
            import("pages/supplyTeam/SupplierAgentCreateTransportRequest.vue"),
        },
        {
          path: "transport_requests/:requestId/edit",
          name: "SupplierAgentEditTransportRequest",
          component: () =>
            import("pages/supplyTeam/SupplierAgentEditTransportRequest.vue"),
        },
      ],
    },
  ],
};

export default supplierTeamAgentRoutes;
